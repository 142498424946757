<template>
  <button
    @click="$emit('click')"
    class="StandardBorderedButton"
    :class="{
      disabled: disabled,
      fluid: fluid,
      backward: backwardsIcon,
      'desktop-fluidity': desktopFluidity,
    }"
  >
    <Arrow v-if="backwardsIcon" color="twilight" />
    <p>{{ title }}</p>
  </button>
</template>

<script>
import Arrow from "@/assets/icons/Arrow";
export default {
  name: "StandardBorderedButton",
  props: [
    "title",
    "disabled",
    "fluid",
    "forwardIcon",
    "backwardsIcon",
    "desktopFluidity",
  ],
  data() {
    return {};
  },
  components: {
    Arrow,
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.StandardBorderedButton {
  background-color: transparent;
  border: 1px solid $twilight;
  color: $twilight;
  height: 48px;
  transition: 0.3s;
  letter-spacing: 0.16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    font-size: 16px;
    @include Gilroy-Bold;
  }

  &.backward {
    svg {
      transform: rotate(180deg);
      margin-right: 9px;
    }
  }
  &.fluid {
    width: auto;
    padding: 0 20px;
  }

  &.disabled {
    border-color: $sleet;
    color: $sleet !important;
    cursor: default;
    background-color: transparent !important;
  }

  &:hover {
    background-color: #fbf1f7;
  }
}

@media (min-width: 1200px) {
  .StandardBorderedButton {
    &.backward {
      flex-wrap: nowrap;
      display: flex;
    }
    &.desktop-fluidity {
      width: auto;
    }
  }
}
</style>
